import * as React from 'react'

function LanguageSelector({ locale, onChangeLanguage }) {
  function handleChange(event) {
    event.preventDefault()
    onChangeLanguage(event.target.value)
  }

  return (
    <div className="select">
      <select onChange={handleChange} value={locale} defaultValue={''}>
        <option value="" disabled hidden>
          en | es | fr
        </option>
        <option value="en">English</option>
        <option value="es">Español</option>
        <option value="fr">Français</option>
      </select>
    </div>
  )
}

export default LanguageSelector
