/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import NavigationI18n from './navigation-i18n'
import FooterI18n from './footer-i18n'
import CookieConsent from 'react-cookie-consent'
/* import { Cookies, getCookieConsentValue } from 'react-cookie-consent'; */
import '../styles/global.scss'

const Layout = (props: LayoutProps) => {
  const { i18n } = useTranslation()

  return (
    <>
      <CookieConsent
        debug={false}
        location="bottom"
        buttonText="I UNDERSTAND & ACCEPT"
        enableDeclineButton={true}
        declineButtonText="I DECLINE"
        cookieName="gatsby-gdpr-google-analytics"
        expires={180}
      >
        This website uses cookies to anonymously analyze traffic, remember user
        preferences, and improve user experience. Because we respect the right
        to privacy of our visitors, we need your informed consent to store or
        retrieve anonymized information in the form of cookies. To learn more
        about our Privacy Policy, you can click the following link. {` `}
        <Link className="back-to-top" to="/terms/">
          More details.
        </Link>
      </CookieConsent>
      <div id="__top" className="container">
        <NavigationI18n locale={i18n.language} defaultLocale="en" />
        {props.children}
        <FooterI18n locale={i18n.language} defaultLocale="en" />
      </div>
    </>
  )
}

interface LayoutProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}

export default Layout
