/**
 * Next.js
 *
 * @see https://github.com/vercel/next.js/blob/canary/packages/next/next-server/lib/router/router.ts
 */

import { normalizePathTrailingSlash } from './normalize-trailing-slash'

export function addLocale(
  path: string,
  locale?: string,
  defaultLocale?: string
) {
  const pathLower = path.toLowerCase()
  const localeLower = locale && locale.toLowerCase()

  return locale &&
    locale !== defaultLocale &&
    !pathLower.startsWith('/' + localeLower + '/') &&
    pathLower !== '/' + localeLower
    ? addPathPrefix(path, '/' + locale)
    : path
}

export function delLocale(path: string, locale?: string) {
  const pathLower = path.toLowerCase()
  const localeLower = locale && locale.toLowerCase()

  return locale &&
    (pathLower.startsWith('/' + localeLower + '/') ||
      pathLower.startsWith('/' + localeLower))
    ? (pathLower.length === locale.length + 1 ? '/' : '') +
    path.substr(locale.length + 1)
    : path
}

function addPathPrefix(path: string, prefix?: string) {
  return prefix && path.startsWith('/')
    ? path === '/'
      ? normalizePathTrailingSlash(prefix)
      : `${prefix}${path === '/' ? path.substring(1) : path}`
    : path
}
