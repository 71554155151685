import { trim } from "lodash"
/**
 *
 * @param path
 * @param prefix
 * @returns
 */
export function prefixPath(path: string, prefix: string): string {
  return `/${prefix}/${trim(path, "/")}`.replace(/\/+/g, "/")
}
/**
 *
 * @param path
 * @param locale
 * @param defaultLocale
 * @param prefixDefault
 * @returns
 */
export function prefixPathI18n(
  path: string,
  locale: string,
  defaultLocale: string = "en",
  prefixDefault: boolean = false): string {

  let link = locale ? prefixPath(path, locale) : prefixPath(path, defaultLocale)
  return prefixDefault ? link : link.replace(`/${defaultLocale}/`, "/")
}
