/**
 * NavbarLinksI18n component that...
 *
 */
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import LinkI18n from './link-i18n'
import { I18nProps } from '../api/i18n/i18n'

const NavbarLinksI18n = (props: I18nProps) => {
  const { t } = useTranslation()
  return (
    <ul id="menu" className="navbar-nav">
      <li className="nav-link">
        <LinkI18n to="contact" locale={props.locale}>
          <Trans i18nKey="nav.contact" t={t}>
            Contact
          </Trans>
        </LinkI18n>
      </li>
    </ul>
  )
}

export default NavbarLinksI18n
