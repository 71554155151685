/**
 * Next.js
 *
 * @see https://github.com/vercel/next.js/blob/canary/packages/next/client/normalize-trailing-slash.ts
 */

/**
 * Removes trailing slash of a path if there is one.
 * Preserves the root path `/`.
 */
export function removePathTrailingSlash(path: string): string {
  return path.endsWith('/') && path !== '/' ? path.slice(0, -1) : path
}

/**
 * Normalizes trailing slash of a path according to
 * `OD_TRAILING_SLASH` env variable
 */
export const normalizePathTrailingSlash = process.env.OD_TRAILING_SLASH
  ? (path: string): string => {
    if (/\.[^/]+\/?$/.test(path)) {
      return removePathTrailingSlash(path)
    } else if (path.endsWith('/')) {
      return path
    } else {
      return path + '/'
    }
  }
  : removePathTrailingSlash
