/**
 * The API exposed by this React hook is very
 * similar to the useRouter React hook API in
 * Next.js
 * @see https://nextjs.org/docs/api-reference/next/router
 *
 * Current implementation is just a wrapper
 * for `react-i18next`, but it could change
 */
import { useTranslation } from 'react-i18next'

function useLocale(): any {
  const { i18n } = useTranslation()

  function changeLocale(locale: string) {
    i18n.changeLanguage(locale)
  }

  /**
   *
   * @returns the configured default locale
   */
  function defaultLocale(): string {
    return i18n.languages[i18n.languages.length - 1]
  }

  /**
   *
   * @returns the currently active locale
   */
  function locale(): string {
    return i18n.language
  }

  /**
   *
   * @returns all configured locales
   */
  function locales(): string[] | boolean {
    return i18n.options.supportedLngs
  }

  return { changeLocale, defaultLocale, locale, locales }
}

export default useLocale
