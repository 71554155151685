/**
 * The API exposed by this component is very
 * similar to the locale-aware Link API in
 * Next.js
 */
import * as React from 'react'
import { Link } from 'gatsby'
import useLocale from '../hooks/use-locale'
import { prefixPathI18n } from '../../utils/i18n-tools'

const LinkI18n = (props: LinkI18nProps) => {
  const { defaultLocale } = useLocale()
  return (
    <Link
      className={props.className}
      to={prefixPathI18n(props.to, props.locale, defaultLocale())}
    >
      {props.children}
    </Link>
  )
}

interface LinkI18nProps {
  className?: string
  to: string
  locale: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}

export default LinkI18n
