/**
 * NavigationI18n component that...
 *
 */
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import useSiteMetadata from '../hooks/use-site-metadata'
import NavbarLinksI18n from './navbar-links-i18n'
import imgLogo from '../images/site-logo.svg'
import { I18nProps } from '../api/i18n/i18n'
import LinkI18n from './link-i18n'

const NavigationI18n = (props: I18nProps) => {
  const { t } = useTranslation()
  const { name, subtitle } = useSiteMetadata()

  return (
    <nav className="l-navigation bg-dark">
      <div className="l-constrained navbar navbar-dark">
        <div className="navbar-brand">
          <LinkI18n className="brand-image" to="/" locale={props.locale}>
            <img
              src={imgLogo}
              alt="Blue and green shades lotus inspired logo"
              width="71"
              height="51"
            />
          </LinkI18n>
          <LinkI18n className="brand-name" to="/" locale={props.locale}>
            {name}
          </LinkI18n>
          <Link className="brand-tagline" to="/#what-we-do">
            {subtitle}
          </Link>
        </div>
        <button
          id="toggle"
          className="navbar-toggle"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span>MENU</span>
        </button>
        <NavbarLinksI18n
          locale={props.locale}
          defaultLocale={props.defaultLocale}
        />
      </div>
    </nav>
  )
}

export default NavigationI18n
