import { graphql, useStaticQuery } from 'gatsby'

function useSiteMetadata(): SiteMetadata {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            name
            title
            subtitle
            tagline
            description
            author
            siteUrl
            ogImage
            favicon {
              ico
              sm
              lg
            }
            lang
          }
        }
      }
    `
  )
  return data.site.siteMetadata
}
export interface SiteMetadataResult {
  site: {
    siteMetadata: SiteMetadata
  }
}
export interface SiteMetadata {
  name: string
  title: string
  subtitle: string
  tagline: string
  description: string
  siteUrl: string
  ogImage: string
  favicon: Favicon
  lang: string
  author: string
}
interface Favicon {
  ico: string
  sm: string
  lg: string
}
export default useSiteMetadata
