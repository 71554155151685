/**
 * FooterI18n component that...
 *
 */
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, navigate } from 'gatsby'
import useSiteMetadata from '../hooks/use-site-metadata'
import useLocale from '../hooks/use-locale'
import { addLocale, delLocale } from '../packages/router'
import imgSiteBadge from '../images/green-site-badge.webp'
//import SocialFollow from "./social-follow"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUniversalAccess } from '@fortawesome/free-solid-svg-icons'
import { I18nProps } from '../api/i18n/i18n'
import LanguageSelector from './language-selector'
import LinkI18n from './link-i18n'

const FooterI18n = (props: I18nProps) => {
  const { t } = useTranslation()
  const { name } = useSiteMetadata()
  const { changeLocale, defaultLocale, locale, locales } = useLocale()

  const handleLanguageChange = (lang: string) => {
    if (typeof window === 'undefined') {
      return
    }
    if (locale() === lang) {
      // do nothing
      return
    }

    const pathName = window.location.pathname
    const noLocalePath = delLocale(pathName, locale())
    const newLocalePath = addLocale(noLocalePath, lang, defaultLocale())
    //console.log('----- path: ', pathName)
    //console.log('----- noLocalePath: ', noLocalePath)
    //console.log('----- newLocalePath: ', newLocalePath)
    changeLocale(lang)
    navigate(newLocalePath)

    /*     const localePrefix = pathName
          .split('/')
          .find(item => locales().includes(item))
        const newPathname = prefixPathI18n(pathName.replace(localePrefix, ""), lang)
        i18n.changeLanguage(lang)
        console.log('----- newPathname: ', newPathname)
        navigate(newPathname) */
  }

  return (
    <footer className="l-footer">
      <div className="l-constrained text-light">
        <div className="l-grid-3">
          <section className="text-small">
            <h3>
              <Trans i18nKey="footer.about" t={t}>
                About
              </Trans>
            </h3>
            <p>
              Imagine... It all started because our founders, global citizens
              and parents, wanted to realize the promise of a more equitable,
              participatory, and sustainable world and digital society. We
              believe that everyone (including our children), should be able to
              participate in a digital society that respects them, rather than
              exploit them. We are not the only ones.
            </p>
          </section>
          <section className="text-small">
            <h3>
              <Trans i18nKey="footer.links" t={t}>
                Quick Links
              </Trans>
            </h3>
            <ul>
              <li>
                <LinkI18n to="/blog/" locale={props.locale}>
                  Blog
                </LinkI18n>
              </li>
              <li>
                <LinkI18n to="#" locale={props.locale}>
                  Health
                </LinkI18n>
              </li>
              <li>
                <LinkI18n to="#" locale={props.locale}>
                  <Trans i18nKey="footer.sustainability" t={t}>
                    Sustainability
                  </Trans>
                </LinkI18n>
              </li>
              <li>
                <Link to="/terms/">Terms &amp; Privacy</Link>
              </li>
              <li>
                {/* FIX change to sitemap.xml once gatsby-plugin-sitemap BUG is fixed */}
                <a href="/sitemap-0.xml">Sitemap</a>
              </li>
            </ul>
          </section>
          <section className="text-small">
            <h3>
              <Trans i18nKey="footer.sustainability" t={t}>
                Sustainability
              </Trans>
            </h3>
            <p>
              To learn more about the benefits of green web hosting, click on
              the eco-friendly badge below.
            </p>
            <Link to="/2020-07-13-benefits-green-energy-web-hosting/">
              <img
                src={imgSiteBadge}
                alt="World globe eco-friendly website badge"
                width="113"
                height="113"
              />
            </Link>
          </section>
        </div>
        <section className="text-small">{/* <SocialFollow /> */}</section>
        <section className="text-smaller">
          <p>
            {name} {new Date().getFullYear()}. Powered by{` `}
            <a
              href="https://www.gatsbyjs.com"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Gatsby
            </a>
            .<br />
            Except where otherwise <Link to="/docs/">noted</Link>, content on
            this site is licensed under a Creative Commons Attribution 4.0
            International license CC BY-NC-SA 4.0.
            <br />
            No bits were harmed during the making of this site.
          </p>
          <p>
            <a href="/accessibility-statement/">
              <FontAwesomeIcon
                icon={faUniversalAccess}
                size="2x"
                aria-hidden="true"
              />
              {` `}
              <Trans i18nKey="footer.accessibilityStatement" t={t}>
                Accessibility statement.
              </Trans>
            </a>
          </p>
          <LanguageSelector
            language=""
            onChangeLanguage={handleLanguageChange}
          />
        </section>
      </div>
    </footer>
  )
}

export default FooterI18n
